export const homeMenu = {};

export const dashboardMenu3 ={
    dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/counselor/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

    manageAstrologer: { 
		id: 'managea_strologer',
		text: 'Manage Leads',
		path: '#',
		icon: 'Article',
		subMenu: {
			// AddAstro: {
			// 	id: 'addAstro',
			// 	text: 'Add Lead',
			// 	path: '/counselor/add-lead.html',
			// 	icon: 'ViewArray',
			// },
			// ManageAstro: {
			// 	id: 'manageAstro',
			// 	text: 'Manage Lead',
			// 	path: '/counselor/leads.html',
			// 	icon: 'ViewDay',
			// },
			SchoolLead: {
				id: 'SchoolLead',
				text: 'Manage School Lead',
				path: '/counselor/school-leads.html',
				icon: 'ViewDay',
			},
			CoachingLead: {
				id: 'CoachingLead',
				text: 'Manage Coaching Lead',
				path: '/counselor/coaching-leads.html',
				icon: 'ViewDay',
			},
		},
	},

	masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/counselor/profile.html',
				icon: 'ViewDay',
			},
		},
	},
    
}

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu3 ={

    dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/counselor/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},
    manageAstrologer: { 

		id: 'managea_strologer',
		text: 'Manage Leads',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddAstro: {
				id: 'addAstro',
				text: 'Add Lead',
				path: '/counselor/add-lead.html',
				icon: 'ViewArray',
			},
			ManageAstro: {
				id: 'manageAstro',
				text: 'Manage Lead',
				path: '/counselor/leads.html',
				icon: 'ViewDay',
			},
			SchoolLead: {
				id: 'SchoolLead',
				text: 'Manage School Lead',
				path: '/counselor/school-leads.html',
				icon: 'ViewDay',
			},
			CoachingLead: {
				id: 'CoachingLead',
				text: 'Manage Coaching Lead',
				path: '/counselor/coaching-leads.html',
				icon: 'ViewDay',
			},

		},
	},

    reports: { 
		id: 'reports',
		text: 'Reports',
		path: '#',
		icon: 'Article',
		subMenu: {
			report1: {
				id: 'report1',
				text: 'Report 1',
				path: '/counselor/report1.html',
				icon: 'ViewArray',
			},
			report2: {
				id: 'report2',
				text: 'Report 2',
				path: '/counselor/report2.html',
				icon: 'ViewArray',
			},
			report3: {
				id: 'report3',
				text: 'Report 3',
				path: '/counselor/report3.html',
				icon: 'ViewArray',
			},
		},
	},

    masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/counselor/profile.html',
				icon: 'ViewDay',
			},
		},
	},
}

export const productsMenu = {};