import React, { lazy } from 'react';
import { dashboardMenu } from '../menu';
import { dashboardMenu1 } from '../menu_department';
import { dashboardMenu2 } from '../menu_agent';
import { dashboardMenu3 } from '../menu_counselor';

import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../ekon/superadmin/Dashboard')),
	AddLead: lazy(() => import('../ekon/superadmin/lead/AddLead')),
	ManageLead: lazy(() => import('../ekon/superadmin/lead/ViewLead')),
	SchoolLead: lazy(() => import('../ekon/superadmin/lead/SchoolLead')),
	CoachingLead: lazy(() => import('../ekon/superadmin/lead/CoachingLead')),
	AssignedLead: lazy(() => import('../ekon/superadmin/lead/AssignedLead')),
	BulkLead: lazy(() => import('../ekon/superadmin/lead/BulkLead')),
	AssignLead: lazy(() => import('../ekon/superadmin/lead/AssignLead')),

	Profile: lazy(() => import('../ekon/superadmin/setting/Profile')),
	Department: lazy(() => import('../ekon/superadmin/setting/department/Department')),
	Role: lazy(()=>import('../ekon/superadmin/setting/role/Role')),
	Agent: lazy(() => import('../ekon/superadmin/setting/agent/Agent')),
	Category: lazy(() => import('../ekon/superadmin/setting/category/Category')),
	sources: lazy(() => import('../ekon/superadmin/setting/sources/Sources')),
     
	sender: lazy(() => import('../ekon/superadmin/setting/sender/Sender')),
	template: lazy(() => import('../ekon/superadmin/setting/template/Template')),

	country: lazy(() => import('../ekon/superadmin/location/country/Country')),
	state: lazy(() => import('../ekon/superadmin/location/state/State')),
	city: lazy(() => import('../ekon/superadmin/location/city/City')),
	
	PunchIn: lazy(() => import('../ekon/superadmin/punch/punchin/Punchin')),

	Compose: lazy(() => import('../ekon/superadmin/message/Compose')),

	callerdeskMember: lazy(() => import('../ekon/superadmin/setting/callerdesk/List')),
	members: lazy(() => import('../ekon/superadmin/setting/Members')),
};

const Department = {
	DASHBOARD: lazy(() => import('../ekon/department/Dashboard')),
	AddLead: lazy(() => import('../ekon/department/lead/AddLead')),
	ManageLead: lazy(() => import('../ekon/department/lead/ViewLead')),
	SchoolLead: lazy(() => import('../ekon/department/lead/SchoolLead')),
	CoachingLead: lazy(() => import('../ekon/department/lead/CoachingLead')),
	BulkLead: lazy(() => import('../ekon/department/lead/BulkLead')),

	Profile: lazy(() => import('../ekon/department/setting/Profile')),
	

};

const AgentLogin = {
	DASHBOARD: lazy(() => import('../ekon/agent/Dashboard')),
	AddLead: lazy(() => import('../ekon/agent/lead/AddLead')),
	ManageLead: lazy(() => import('../ekon/agent/lead/ViewLead')),
	SchoolLead: lazy(() => import('../ekon/agent/lead/SchoolLead')),
	CoachingLead: lazy(() => import('../ekon/agent/lead/CoachingLead')),
	Profile: lazy(() => import('../ekon/agent/setting/Profile')),	
};

const CounselorLogin = {
	DASHBOARD: lazy(() => import('../ekon/counselor/Dashboard')),
	// AddLead : lazy(()=> import('../ekon/counselor/lead/AddLead')),
	// ManageLead: lazy(() => import('../ekon/counselor/lead/ViewLead')),
	SchoolLead: lazy(() => import('../ekon/counselor/lead/SchoolLead')),
	CoachingLead: lazy(() => import('../ekon/counselor/lead/CoachingLead')),
	Profile: lazy(() => import('../ekon/counselor/setting/Profile')),
};


const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const presentation = [
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	// Leads
	{
		path: dashboardMenu.manageAstrologer.subMenu.AddAstro.path,
		element: <LANDING.AddLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu.manageAstrologer.subMenu.ManageAstro.path,
		element: <LANDING.ManageLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu.manageAstrologer.subMenu.SchoolLead.path,
		element: <LANDING.SchoolLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu.manageAstrologer.subMenu.CoachingLead.path,
		element: <LANDING.CoachingLead />,
		exact: true,
	}, 
	
	{
		path: dashboardMenu.manageAstrologer.subMenu.assigned.path,
		element: <LANDING.AssignedLead />,
		exact: true,
	},
	{
		path: dashboardMenu.manageAstrologer.subMenu.BulkUpload.path,
		element: <LANDING.BulkLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu.manageAstrologer.subMenu.assign.path,
		element: <LANDING.AssignLead />,
		exact: true,
	},

	
	// Masters
	{
		path: dashboardMenu.masters.subMenu.department.path,
		element: <LANDING.Department />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.role.path,
		element: <LANDING.Role/>,
		exact: true,
	}, 

	{
		path: dashboardMenu.masters.subMenu.agent.path,
		element: <LANDING.Agent />,
		exact: true,
	}, 
	{
		path: dashboardMenu.masters.subMenu.category.path,
		element: <LANDING.Category />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.sources.path,
		element: <LANDING.sources />,
		exact: true,
	}, 
	{
		path: dashboardMenu.masters.subMenu.callerDesk.path,
		element: <LANDING.callerdeskMember />,
		exact: true,
	}, 

	{
		path: dashboardMenu.masters.subMenu.members.path,
		element: <LANDING.members />,
		exact: true,
	}, 

	{
		path: dashboardMenu.masters.subMenu.sender.path,
		element: <LANDING.sender />,
		exact: true,
	}, 

	{
		path: dashboardMenu.masters.subMenu.template.path,
		element: <LANDING.template />,
		exact: true,
	}, 
	

	{
		path: dashboardMenu.masters.subMenu.profile.path,
		element: <LANDING.Profile />,
		exact: true,
	}, 


	// Location
	{
		path: dashboardMenu.locations.subMenu.country.path,
		element: <LANDING.country />,
		exact: true,
	},
	{
		path: dashboardMenu.locations.subMenu.state.path,
		element: <LANDING.state />,
		exact: true,
	},
	{
		path: dashboardMenu.locations.subMenu.city.path,
		element: <LANDING.city />,
		exact: true,
	},

	// Punch In --

	{
		path: dashboardMenu.punch.subMenu.punchin.path,
		element: <LANDING.PunchIn />,
		exact: true,
	},

	{
		path: dashboardMenu.message.subMenu.compose.path,
		element: <LANDING.Compose />,
		exact: true,
	},
	
	
	
	

	/** ************************************************** */
	
	{
		path: '/',
		element: <Login />,
		exact: true,
	},


	//======== Department Menu........
	{
		path: dashboardMenu1.dashboard.path,
		element: <Department.DASHBOARD />,
		exact: true,
	},

	// Leads
	{
		path: dashboardMenu1.manageAstrologer.subMenu.AddAstro.path,
		element: <Department.AddLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu1.manageAstrologer.subMenu.ManageAstro.path,
		element: <Department.ManageLead />,
		exact: true,
	},
	{
		path: dashboardMenu1.manageAstrologer.subMenu.SchoolLead.path,
		element: <Department.SchoolLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu1.manageAstrologer.subMenu.CoachingLead.path,
		element: <Department.CoachingLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu1.manageAstrologer.subMenu.BulkLead.path,
		element: <Department.BulkLead />,
		exact: true,
	}, 

	 

	{
		path: dashboardMenu1.masters.subMenu.profile.path,
		element: <Department.Profile />,
		exact: true,
	}, 

	


	//======== Agent Menu........
	{
		path: dashboardMenu2.dashboard.path,
		element: <AgentLogin.DASHBOARD />,
		exact: true,
	},

	// Leads
	{
		path: dashboardMenu2.manageAstrologer.subMenu.AddAstro.path,
		element: <AgentLogin.AddLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu2.manageAstrologer.subMenu.ManageAstro.path,
		element: <AgentLogin.ManageLead />,
		exact: true,
	},
	{
		path: dashboardMenu2.manageAstrologer.subMenu.SchoolLead.path,
		element: <AgentLogin.SchoolLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu2.manageAstrologer.subMenu.CoachingLead.path,
		element: <AgentLogin.CoachingLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu2.masters.subMenu.profile.path,
		element: <AgentLogin.Profile />,
		exact: true,
	}, 



	//======== Counselor Menu........
	
	{
		path: dashboardMenu3.dashboard.path,
		element: <CounselorLogin.DASHBOARD/>,
		exact:true,
	},
	// {
	// 	path:dashboardMenu3.manageAstrologer.subMenu.AddAstro.path,
	// 	element:<CounselorLogin.AddLead/>
	// },

	// {
	// 	path: dashboardMenu3.manageAstrologer.subMenu.ManageAstro.path,
	// 	element: <CounselorLogin.ManageLead/>,
	// 	exact: true,
	// },
	{
		path: dashboardMenu3.manageAstrologer.subMenu.SchoolLead.path,
		element: <CounselorLogin.SchoolLead />,
		exact: true,
	}, 

	{
		path: dashboardMenu3.manageAstrologer.subMenu.CoachingLead.path,
		element: <CounselorLogin.CoachingLead />,
		exact: true,
	}, 
	{
		path: dashboardMenu3.masters.subMenu.profile.path,
		element: <CounselorLogin.Profile />,
		exact: true,
	}, 
	


];
const documentation = [];
const contents = [...presentation];
export default contents;
