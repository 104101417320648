

export const homeMenu = {};

export const dashboardMenu = {

	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/superadmin/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	
	manageAstrologer: { 
		id: 'managea_strologer',
		text: 'Manage Leads',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddAstro: {
				id: 'addAstro',
				text: 'Add Lead',
				path: '/superadmin/add-lead.html',
				icon: 'ViewArray',
			},
			ManageAstro: {
				id: 'manageAstro',
				text: 'Manage Lead',
				path: '/superadmin/leads.html/:cid',
				icon: 'ViewDay',
			},
			SchoolLead: {
				id: 'SchoolLead',
				text: 'Manage School Lead',
				path: '/superadmin/school-leads.html',
				icon: 'ViewDay',
			},
			CoachingLead: {
				id: 'CoachingLead',
				text: 'Manage Coaching Lead',
				path: '/superadmin/coaching-leads.html',
				icon: 'ViewDay',
			},
			BulkUpload: {
				id: 'bulkUpload',
				text: 'Lead Bulk Upload',
				path: '/superadmin/bulk-leads.html',
				icon: 'ViewDay',
			},
			assign: {
				id: 'assign',
				text: 'Lead Assign',
				path: '/superadmin/lead-assign.html',
				icon: 'ViewDay',
			},
			assigned: {
				id: 'assigned',
				text: 'Assigned Lead',
				path: '/superadmin/assign-leads.html',
				icon: 'ViewDay',
			},
		},
	},
	
	locations: { 
		id: 'locations',
		text: 'Location Master',
		path: '#',
		icon: 'Article',
		subMenu: {
			country: {
				id: 'country',
				text: 'Country Management',
				path: '/superadmin/country.html',
				icon: 'ViewArray',
			},
			state: {
				id: 'state',
				text: 'State Management',
				path: '/superadmin/state.html',
				icon: 'ViewArray',
			},
			city: {
				id: 'city',
				text: 'City Management',
				path: '/superadmin/city.html',
				icon: 'ViewDay',
			},
		},
	},
	masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			department: {
				id: 'department',
				text: 'Department Management',
				path: '/superadmin/department.html',
				icon: 'ViewArray',
			},
			role: {
				id: 'role',
				text: 'Role Management',
				path: '/superadmin/role.html',
				icon: 'ViewArray',
			},
			agent: {
				id: 'agent',
				text: 'Agent Management',
				path: '/superadmin/agent.html',
				icon: 'ViewArray',
			},
			category: {
				id: 'category',
				text: 'Category Management',
				path: '/superadmin/category.html',
				icon: 'ViewDay',
			},
			sources: {
				id: 'sources',
				text: 'Source Management',
				path: '/superadmin/source.html',
				icon: 'ViewDay',
			},
			members: {
				id: 'members',
				text: 'All Members',
				path: '/superadmin/members.html',
				icon: 'ViewArray',
			},
			callerDesk: {
				id: 'callerdesk',
				text: 'Caller Desk Member',
				path: '/superadmin/callerdesk-member.html',
				icon: 'ViewDay',
			},
			sender: {
				id: 'sender',
				text: 'Sender Management',
				path: '/superadmin/sender.html',
				icon: 'ViewDay',
			},
			template: {
				id: 'template',
				text: 'Template Management',
				path: '/superadmin/template.html',
				icon: 'ViewDay',
			},
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/superadmin/profile.html',
				icon: 'ViewDay',
			},
		},
	},
	punch: { 
		id: 'punch',
		text: 'Attendance',
		path: '#',
		icon: 'Article',
		subMenu: {
			punchin: {
				id: 'punchin',
				text: 'Mark Punch',
				path: '/superadmin/punch.html',
				icon: 'ViewArray',
			},
		},
	},

	message: { 
		id: 'message',
		text: 'Message',
		path: '#',
		icon: 'Article',
		subMenu: {
			compose: {
				id: 'compose',
				text: 'Compose',
				path: '/superadmin/compose.html',
				icon: 'ViewArray',
			},
		},
	},
	reports: { 
		id: 'reports',
		text: 'Reports',
		path: '#',
		icon: 'Article',
		subMenu: {
			report1: {
				id: 'report1',
				text: 'Today Assigned',
				path: '/superadmin/report1.html',
				icon: 'ViewArray',
			},
			report2: {
				id: 'report2',
				text: 'Today Followup',
				path: '/superadmin/report2.html',
				icon: 'ViewArray',
			},
			report3: {
				id: 'report3',
				text: 'Report 3',
				path: '/superadmin/report3.html',
				icon: 'ViewArray',
			},
		},
	},
};

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/superadmin/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	
	manageAstrologer: { 
		id: 'managea_strologer',
		text: 'Manage Leads',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddAstro: {
				id: 'addAstro',
				text: 'Add Lead',
				path: '/superadmin/add-lead.html',
				icon: 'ViewArray',
			},
			ManageAstro: {
				id: 'manageAstro',
				text: 'Manage Lead',
				path: '/superadmin/leads.html',
				icon: 'ViewDay',
			},
			SchoolLead: {
				id: 'SchoolLead',
				text: 'Manage School Lead',
				path: '/superadmin/school-leads.html',
				icon: 'ViewDay',
			},
			CoachingLead: {
				id: 'CoachingLead',
				text: 'Manage Coaching Lead',
				path: '/superadmin/coaching-leads.html',
				icon: 'ViewDay',
			},
			BulkUpload: {
				id: 'bulkUpload',
				text: 'Lead Bulk Upload',
				path: '/superadmin/bulk-leads.html',
				icon: 'ViewDay',
			},
			assigned: {
				id: 'assigned',
				text: 'Assigned Lead',
				path: '/superadmin/assign-leads.html',
				icon: 'ViewDay',
			},
		},
	},
	
	masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			department: {
				id: 'department',
				text: 'Department Management',
				path: '/superadmin/department.html',
				icon: 'ViewArray',
			},
			role: {
				id: 'role',
				text: 'Role Management',
				path: '/superadmin/role.html',
				icon: 'ViewArray',
			},
			
			agent: {
				id: 'agent',
				text: 'Agent Management',
				path: '/superadmin/agent.html',
				icon: 'ViewArray',
			},
			category: {
				id: 'category',
				text: 'Category Management',
				path: '/superadmin/category.html',
				icon: 'ViewDay',
			},
			members: {
				id: 'members',
				text: 'All Members',
				path: '/superadmin/members.html',
				icon: 'ViewArray',
			},
			callerDesk: {
				id: 'callerdesk',
				text: 'Caller Desk Member',
				path: '/superadmin/callerdesk-member.html',
				icon: 'ViewDay',
			},
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/superadmin/profile.html',
				icon: 'ViewDay',
			},
		},
	},
	reports: { 
		id: 'reports',
		text: 'Reports',
		path: '#',
		icon: 'Article',
		subMenu: {
			report1: {
				id: 'report1',
				text: 'Report 1',
				path: '/superadmin/report1.html',
				icon: 'ViewArray',
			},
			report2: {
				id: 'report2',
				text: 'Report 2',
				path: '/superadmin/report2.html',
				icon: 'ViewArray',
			},
			report3: {
				id: 'report3',
				text: 'Report 3',
				path: '/superadmin/report3.html',
				icon: 'ViewArray',
			},
		},
	},
};

export const productsMenu = {};
